import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";
import swal from "sweetalert";
import Select from "react-select";
import Spinner from "../../elements/Spinner";

export default function Mailbox(props) {
  const [spinner, setSpinner] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const handleNewMailClick = () => {
    setIsComposing(true);
  };
  const handleCancelCompose = () => {
    setIsComposing(false);
  };

  //Fetch Mail
  const [emails, setEmails] = useState([]);
  const getEmails = async () => {
    setSpinner(true);
    var response = await api.get("/get-emails");
    if (response.status === 200 && response.data) {
      setEmails(response.data.inbox_messages);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  useEffect(async () => {
    getEmails();
  }, []);

  const [mailDetails, setMailDetails] = useState({});
  const showDetails = async (mailId) => {
    setSpinner(true);
    var response = await api.post("/get-email-details", { mailId: mailId });
    if (response.status === 200 && response.data) {
      setMailDetails(response.data.email);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="email_laout">
        <div className="email-sidebar">
          <button className="new-mail" onClick={handleNewMailClick}>
            New Mail
          </button>
          <div className="search-bar">
            <input type="text" placeholder="Mail Search" />
          </div>
          <div className="email-section">
            <h3>Favorite</h3>
            <ul>
              <li>
                Inbox <span className="badge">63</span>
              </li>
              <li>Priority</li>
              <li>
                Sent Items <span className="badge">63</span>
              </li>
              <li>
                Drafts <span className="badge">63</span>
              </li>
              <li>
                Deleted Items <span className="badge">63</span>
              </li>
            </ul>
          </div>
          <div className="email-section">
            <h3>Folders</h3>
            <ul>
              <li>
                Archives <span className="badge">63</span>
              </li>
              <li>Junk Email</li>
              <li>Notes</li>
              <li>Marketing</li>
              <li>Personal</li>
            </ul>
          </div>
          <div className="email-section">
            <h3>WO-Wise</h3>
            <ul>
              <li>FW0001</li>
              <li>FK0011</li>
              <li>FJ0012</li>
            </ul>
          </div>
          <div className="email-section">
            <h3>PO-Wise</h3>
            <ul>
              <li>KQ1001</li>
              <li>PK1001</li>
              <li>QB1001</li>
            </ul>
          </div>
        </div>

        <div className="inbox">
          <div className="inbox-header">
            <span>Inbox</span>
            <div className="filters">
              <button>All</button>
              <button>Unread</button>
              <button>To me</button>
            </div>
          </div>
          <hr />
          <ul className="email-list">
            {emails.map((item, index) => (
              <li
                onClick={() => showDetails(item.id)}
                key={index}
                className="email-item "
              >
                <span className="subject">
                  <imput type="checkbox" />
                  {item.subject}
                </span>
                <span className="from">{item.senderName}</span>
                <span className="time">{item.date}</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="email-detail">
          {isComposing ? (
            <div className="compose-email">
              <div className="compose-header">
                <h2>Compose New Email</h2>
                <button
                  className="cancel-btn btn btn-danger"
                  onClick={handleCancelCompose}
                >
                  Cancel
                </button>
              </div>

              <div className="compose-body">
                <input type="text" placeholder="To" />
                <br />
                <input type="text" placeholder="Subject" />
                <br />
                <textarea placeholder="Compose your email..."></textarea>
                <button className="send-btn">Send</button>
              </div>
            </div>
          ) : (
            <>
              <div className="email-content">
                <div className="email-header">
                  <div className="text-end">
                    <button>Reply</button>
                    <button>Reply All</button>
                    <button>Forward</button>
                  </div>
                  <div className="header-left">
                    <h2>{mailDetails.subject}</h2>
                    <div className="email-info">
                      <small>To: {mailDetails.toString}</small>
                      <br />
                      <small>CC: {mailDetails.ccString}</small>
                    </div>
                  </div>
                </div>
                <div className="email-body">
                  <br />
                  <pre>{mailDetails.body}</pre>

                  <br />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
