// Sidebar.js
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { hasPermission } from "../routes/permissions/CheckPermissions";
import {
  defaultConfig,
  rolesPermissions,
} from "../routes/permissions/PermissionsConfig";

export default function Sidebar(props) {
  const location = useLocation();
  const pathname = location.pathname;
  const userData = props.userData;

  return (
    <div
      className={
        props.sidebar
          ? "sidebar falgun-sidebar pb-3 bg-falgun"
          : "sidebar pb-3 falgun-sidebar open bg-falgun"
      }
    >
      <nav className="navbar bg-falgun">
        <a href="/dashboard" className="navbar-brand mx-4 mb-1">
          <img
            width={150}
            src={require("../assets/images/logos/logo-white.png").default}
          />
        </a>

        <div className="text-center d-block w-100">
          <img
            className="rounded-circle border"
            src={userData?.profile_picture}
            alt={userData?.full_name}
            style={{
              width: "40px",
              height: "40px",
            }}
          />
          <div>{userData?.full_name}</div>
          <small>{userData?.designation_title}</small>
        </div>
        <hr className="w-100" />

        <div className="navbar-nav w-100">
          <Link
            to="/dashboard"
            className={
              pathname === "/dashboard"
                ? "nav-item nav-link active"
                : "nav-item nav-link"
            }
          >
            <i className="fa fa-tachometer-alt me-1"></i>Dashboard
          </Link>

          {Object.keys(rolesPermissions).map(
            (department, index) =>
              userData?.department_title === department &&
              Object.keys(rolesPermissions[department]).map(
                (designation, idx) =>
                  userData?.designation_title === designation &&
                  rolesPermissions[department][designation].map(
                    (item, subIndex) =>
                      hasPermission(userData, item.path) && (
                        <Link
                          to={item.path}
                          className={
                            pathname === item.path
                              ? "nav-item nav-link active"
                              : "nav-item nav-link"
                          }
                          key={`${index}-${idx}-${subIndex}`}
                        >
                          <i className={item.icon}></i>
                          {item.label}
                        </Link>
                      )
                  )
              )
          )}
          <hr className="w-100" />
          {defaultConfig.map(
            (item, index) =>
              hasPermission(userData, item.path) && (
                <Link
                  to={item.path}
                  className={
                    pathname === item.path
                      ? "nav-item nav-link active"
                      : "nav-item nav-link"
                  }
                  key={index}
                >
                  <i className={item.icon}></i>
                  {item.label}
                </Link>
              )
          )}
        </div>
      </nav>
    </div>
  );
}
