import React, { useState, useEffect, useContext } from "react";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import { Link } from "react-router-dom";
import moment from "moment";
import api from "services/api";
import ls from "../services/ls";
import { Badge } from "react-bootstrap";

const Header = (props) => {
  const { updateUserObj } = useContext(AppContext);


  const isAuthenticated = props.isAuthenticated;
  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  const [messages, setMessages] = useState([]);
  const getMessages = async () => {
    var response = await api.post("/notebooks");
    if (response.status === 200 && response.data) {
      setMessages(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const markAsRead = async (id) => {
    try {
      const response = await api.post("/notifications-read", {
        id: id,
      });
      if (response.status === 200 && response.data) {
        // After marking the notification as read, you may choose to update the notifications list
        props.setCallNotifications(true);

        // Delay for 500 milliseconds before setting the notification back to false
        setTimeout(() => {
          props.setCallNotifications(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error handling notification click:", error);
    }
  };

  useEffect(async () => {
    getMessages();
  }, []);

  return (
    <header>
      <nav className="navbar navbar-expand bg-falgun sticky-top px-3 py-0 create_edit_page">
        <Link to="/dashboard" className="navbar-brand d-flex d-lg-none me-4">
          <h2 className="text-primary mb-0">
            <img
              width={30}
              src={
                require("../assets/images/logos/logo-short-white.png").default
              }
            />
          </h2>
        </Link>
        <Link
          style={{ textDecoration: "none" }}
          onClick={() => {
            const newSidebarValue = !props.sidebar;
            ls.set("sidebar", newSidebarValue);
            props.setSideBar(newSidebarValue);
          }}
          className="sidebar-toggler flex-shrink-0"
        >
          <i className="fa fa-bars text-falgun"></i>
        </Link>
        <form className="d-none d-md-flex ms-4">
          <input
            className="form-control border-0 margin_bottom_0"
            type="search"
            placeholder="Search"
          />
        </form>
        <div className="navbar-nav align-items-center ms-auto">
          <div className="nav-item dropdown">
            <Link
              href="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <i className="fa fa-envelope me-lg-2 text-falgun"></i>
              <span className="d-none d-lg-inline-flex">Message</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end bg-falgun border-0 rounded-0 rounded-bottom m-0">
              {messages.length > 0
                ? messages.slice(0, 4).map((item, index) => (
                    <>
                      <Link
                        to={"/messages/" + item.id}
                        className="dropdown-item"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            className="rounded-circle"
                            src={item.user_photo}
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                          <div className="ms-2">
                            <h6 className="fw-normal mb-0 line_limit_one">
                              {item.title}
                            </h6>
                            <small>
                              {moment(item.created_at).format("lll")}
                            </small>
                          </div>
                        </div>
                      </Link>
                      <hr className="dropdown-divider" />
                    </>
                  ))
                : "No Messages Here"}

              <Link to="/messages" className="dropdown-item text-center">
                See all message
              </Link>
            </div>
          </div>
          <div className="nav-item dropdown">
            <Link
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <Badge style={{ position: "absolute", top: "2px" }} bg="danger">
                {props.unreadNotifications.length > 0
                  ? props.unreadNotifications.length
                  : ""}
              </Badge>
              <i className="fa fa-bell me-lg-2 text-falgun"></i>
              <span className="d-none d-lg-inline-flex">Notification</span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end bg-falgun border-0 rounded-0 rounded-bottom m-0">
              {props.unreadNotifications.map((item, index) => (
                <>
                  <Link
                    to={item.url}
                    key={index}
                    onClick={() => markAsRead(item.id)}
                    className="dropdown-item"
                  >
                    <h6 className="fw-normal mb-0">{item.title}</h6>
                    <p style={{ fontSize: "13px" }}>{item.description}</p>
                    <small>
                      {moment(item.created_at).format("MMMM Do YYYY, h:mm A")}
                    </small>
                  </Link>
                  <hr className="dropdown-divider" />
                </>
              ))}

              <a href="#" className="dropdown-item text-center">
                See all notifications
              </a>
            </div>
          </div>
          <div className="nav-item dropdown">
            <Link
              href="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              <img
                className="rounded-circle me-lg-2 border"
                src={props.userData?.profile_picture}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                }}
              />
              <span className="d-none d-lg-inline-flex">
                {props.userData?.full_name}
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-end bg-falgun border-0 rounded-0 rounded-bottom m-0">
              <Link to="/profile" className="dropdown-item">
                <i className="fa fa-user me-2" aria-hidden="true"></i>
                <span>My Profile</span>
              </Link>
              <hr className="dropdown-divider" />
              <Link className="dropdown-item">
                <i className="fa fa-cog me-2" aria-hidden="true"></i>
                <span>Settings</span>
              </Link>
              <hr className="dropdown-divider" />
              <Link onClick={logout} className="dropdown-item">
                <i className="fa fa-sign-out me-2" aria-hidden="true"></i>
                <span>Log out</span>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
