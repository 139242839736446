export const StorePermissions = {
  Manager: [
    {
      path: "/store/receives",
      label: "Receives",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/stores",
      label: "Manage",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/store/issues",
      label: "Issues",
      icon: "fas fa-chevron-right me-1",
    },

    {
      path: "/store/left-overs",
      label: "Left Overs",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/store/returns",
      label: "Return Request",
      icon: "fas fa-chevron-right me-1",
    },

    {
      path: "/sub-stores",
      label: "Sub-Store",
      icon: "fas fa-store me-1",
    },
    //SUBSTORE SUBMENU

    //SUBSTORE SUBMENU

    {
      path: "/sub-stores/Stationery",
      label: "Stationery",
      icon: "fas fa-scroll me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Spare Parts",
      label: "Spare Parts",
      icon: "fas fa-wrench me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Electrical",
      label: "Electrical",
      icon: "fas fa-bolt me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Needle",
      label: "Needle",
      icon: "fas fa-syringe me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Medical",
      label: "Medical",
      icon: "fas fa-capsules me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Fire",
      label: "Fire",
      icon: "fas fa-fire me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compressor & boiler",
      label: "Compressor & boiler",
      icon: "fas fa-stopwatch me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Chemical",
      label: "Chemical",
      icon: "fas fa-flask me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Printing",
      label: "Printing",
      icon: "fas fa-print me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/It",
      label: "It",
      icon: "fas fa-server me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/WTP",
      label: "WTP",
      icon: "fas fa-water me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compliance",
      label: "Compliance",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Vehicle",
      label: "Vehicle",
      icon: "fas fa-car me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Mechanical",
      label: "Mechanical",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/requisitions",
      label: "Requisitions",
      icon: "fas fa-border-all me-1",
    },
    {
      path: "/requisitions-pending",
      label: "Pending Purchase",
      icon: "fas fa-info me-1 ml-25 no_circle",
    },
  ],
  "Assistant Manager": [
    {
      path: "/store/receives",
      label: "Receives",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/stores",
      label: "Manage",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/store/issues",
      label: "Issues",
      icon: "fas fa-chevron-right me-1",
    },

    {
      path: "/store/left-overs",
      label: "Left Overs",
      icon: "fas fa-chevron-right me-1",
    },
    {
      path: "/store/returns",
      label: "Return Request",
      icon: "fas fa-chevron-right me-1",
    },

    {
      path: "/sub-stores",
      label: "Sub-Store",
      icon: "fas fa-store me-1",
    },
    //SUBSTORE SUBMENU

    //SUBSTORE SUBMENU

    {
      path: "/sub-stores/Stationery",
      label: "Stationery",
      icon: "fas fa-scroll me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Spare Parts",
      label: "Spare Parts",
      icon: "fas fa-wrench me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Electrical",
      label: "Electrical",
      icon: "fas fa-bolt me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Needle",
      label: "Needle",
      icon: "fas fa-syringe me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Medical",
      label: "Medical",
      icon: "fas fa-capsules me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Fire",
      label: "Fire",
      icon: "fas fa-fire me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compressor & boiler",
      label: "Compressor & boiler",
      icon: "fas fa-stopwatch me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Chemical",
      label: "Chemical",
      icon: "fas fa-flask me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Printing",
      label: "Printing",
      icon: "fas fa-print me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/It",
      label: "It",
      icon: "fas fa-server me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/WTP",
      label: "WTP",
      icon: "fas fa-water me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compliance",
      label: "Compliance",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Vehicle",
      label: "Vehicle",
      icon: "fas fa-car me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Mechanical",
      label: "Mechanical",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/requisitions",
      label: "Requisitions",
      icon: "fas fa-border-all me-1",
    },
    {
      path: "/requisitions-pending",
      label: "Pending Purchase",
      icon: "fas fa-info me-1 ml-25 no_circle",
    },
  ],
  "SubStore Assistant": [
    {
      path: "/sub-stores",
      label: "Sub-Store",
      icon: "fas fa-store me-1",
    },
    //SUBSTORE SUBMENU

    {
      path: "/sub-stores/Stationery",
      label: "Stationery",
      icon: "fas fa-scroll me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Spare Parts",
      label: "Spare Parts",
      icon: "fas fa-wrench me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Electrical",
      label: "Electrical",
      icon: "fas fa-bolt me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Needle",
      label: "Needle",
      icon: "fas fa-syringe me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Medical",
      label: "Medical",
      icon: "fas fa-capsules me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Fire",
      label: "Fire",
      icon: "fas fa-fire me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compressor & boiler",
      label: "Compressor & boiler",
      icon: "fas fa-stopwatch me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Chemical",
      label: "Chemical",
      icon: "fas fa-flask me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Printing",
      label: "Printing",
      icon: "fas fa-print me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/It",
      label: "It",
      icon: "fas fa-server me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/WTP",
      label: "WTP",
      icon: "fas fa-water me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compliance",
      label: "Compliance",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Vehicle",
      label: "Vehicle",
      icon: "fas fa-car me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Mechanical",
      label: "Mechanical",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },

    {
      path: "/requisitions",
      label: "Requisitions",
      icon: "fas fa-border-all me-1",
    },
    {
      path: "/requisitions-pending",
      label: "Pending Purchase",
      icon: "fas fa-info me-1 ml-25 no_circle",
    },
  ],

  "Store Keeper": [
    {
      path: "/sub-stores",
      label: "Sub-Store",
      icon: "fas fa-store me-1",
    },
    //SUBSTORE SUBMENU
    //SUBSTORE SUBMENU

    {
      path: "/sub-stores/Stationery",
      label: "Stationery",
      icon: "fas fa-scroll me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Spare Parts",
      label: "Spare Parts",
      icon: "fas fa-wrench me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Electrical",
      label: "Electrical",
      icon: "fas fa-bolt me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Needle",
      label: "Needle",
      icon: "fas fa-syringe me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Medical",
      label: "Medical",
      icon: "fas fa-capsules me-1 ml-25 no_circle",
    },

    {
      path: "/sub-stores/Fire",
      label: "Fire",
      icon: "fas fa-fire me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compressor & boiler",
      label: "Compressor & boiler",
      icon: "fas fa-stopwatch me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Chemical",
      label: "Chemical",
      icon: "fas fa-flask me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Printing",
      label: "Printing",
      icon: "fas fa-print me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/It",
      label: "It",
      icon: "fas fa-server me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/WTP",
      label: "WTP",
      icon: "fas fa-water me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Compliance",
      label: "Compliance",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Vehicle",
      label: "Vehicle",
      icon: "fas fa-car me-1 ml-25 no_circle",
    },
    {
      path: "/sub-stores/Mechanical",
      label: "Mechanical",
      icon: "fas fa-lemon me-1 ml-25 no_circle",
    },
    {
      path: "/requisitions",
      label: "Requisitions",
      icon: "fas fa-border-all me-1",
    },
    {
      path: "/requisitions-pending",
      label: "Pending Purchase",
      icon: "fas fa-info me-1 ml-25 no_circle",
    },
  ],
};
