import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function AdminSidebar({ sidebar, userData, section }) {
  const { pathname } = useLocation();

  const isActive = (path, exact = true) =>
    exact ? pathname === path : pathname.startsWith(path);

  const menuItems = [
    {
      label: "Dashboard",
      icon: "fa-tachometer-alt",
      path: "/dashboard",
    },
    {
      label: "Sample",
      icon: "fa-th",
      section: "sample",
      dropdown: [
        {
          label: "Consumptions",
          path: ["/sample/consumptions", "/sample/consumptions-create"],
        },
        {
          label: "SORS",
          path: ["/admin/sors"],
        },
      ],
    },
    {
      label: "Development",
      icon: "fas fa-microchip",
      section: "development",
      dropdown: [
        {
          label: "Designs",
          path: ["/admin/designs"],
        },
      ],
    },
    {
      label: "Merchandising",
      icon: "fa-laptop",
      section: "merchandising",
      dropdown: [
        {
          label: "PC/JOBS",
          path: ["/merchandising/purchase-contracts"],
        },
        {
          label: "Techpack's",
          path: ["/merchandising/techpacks"],
        },
        {
          label: "SORS",
          path: ["/merchandising/sors", "/merchandising/sors-create"],
        },
        {
          label: "BOM & Costings",
          path: [
            "/merchandising/costings",
            "/merchandising/costings-create",
            "/merchandising/costings-edit/",
            "/merchandising/costings-details/",
          ],
          exact: false,
        },
        {
          label: "PO",
          path: [
            "/merchandising/purchases",
            "/merchandising/purchases-create",
            "/merchandising/purchases-edit/",
            "/merchandising/purchases-details/",
          ],
          exact: false,
        },
        {
          label: "Budgets",
          path: [
            "/merchandising/budgets",
            "/merchandising/budgets-create",
            "/merchandising/budgets-edit/",
            "/merchandising/budgets-details/",
          ],
          exact: false,
        },
        {
          label: "Bookings",
          path: [
            "/merchandising/bookings",
            "/merchandising/bookings-create",
            "/merchandising/bookings-edit/",
            "/merchandising/bookings-details/",
          ],
          exact: false,
        },
        {
          label: "Bookings Overview",
          path: ["/merchandising/bookings-overview"],
        },
        {
          label: "Pro. Invoices",
          path: [
            "/merchandising/proformas",
            "/merchandising/proformas-create",
            "/merchandising/proformas-edit/",
            "/merchandising/proformas-details/",
          ],
          exact: false,
        },
      ],
    },
    {
      label: "Commercial",
      icon: "fa-file",
      dropdown: [
        {
          label: "LC",
          path: ["/admin/lcs"],
        },
        {
          label: "Purchase Contracts",
          path: ["/admin/purchase-contracts"],
        },
      ],
    },
    {
      label: "Finishing",
      icon: "fa-check",
      dropdown: [
        {
          label: "Packing Lists",
          path: ["/admin/packing-lists"],
        },
      ],
    },
    {
      label: "Store",
      icon: "fas fa-warehouse",
      section: "stores",
      dropdown: [
        {
          label: "Manage",
          path: ["/admin/stores"],
        },
        {
          label: "Receives",
          path: ["/admin/store-receives"],
        },
        {
          label: "Issues",
          path: ["/admin/store-issues"],
        },
        {
          label: "Left Over (Ready Made)",
          path: ["/admin/store/left-overs"],
        },
      ],
    },
    {
      label: "Settings",
      icon: "fas fa-cog",
      section: "settings",
      dropdown: [
        {
          label: "Teams",
          path: ["/teams"],
        },
        {
          label: "Sample Type",
          path: ["/sors-types"],
        },
        {
          label: "Buyers",
          path: ["/buyers"],
        },
        {
          label: "Suppliers",
          path: ["/suppliers"],
        },
        {
          label: "Terms",
          path: ["/terms"],
        },
        {
          label: "Role Permission",
          path: ["/roles"],
        },
      ],
    },
    {
      label: "Sub Store",
      icon: "fas fa-file",
      path: ["/sub-stores"],
    },
    {
      label: "Files",
      icon: "fas fa-file",
      path: ["/files"],
    },
  ];

  return (
    <div
      className={`sidebar falgun-sidebar pb-3 ${
        sidebar ? "bg-falgun" : "open bg-falgun"
      }`}
    >
      <nav className="navbar bg-falgun">
        <a href="/dashboard" className="navbar-brand mx-4 mb-3">
          <img
            width={150}
            src={require("../assets/images/logos/logo-white.png").default}
            alt="Logo"
          />
          <br />
          <div className="text-center">ADMIN</div>
        </a>
        <div className="d-flex align-items-center ms-1 mb-4">
          <div className="position-relative">
            <img
              className="rounded-circle"
              src={userData?.profile_picture}
              alt=""
              style={{ width: "40px", height: "40px" }}
            />
            <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1"></div>
          </div>
          <div className="ms-2">
            <h6 className="mb-0">{userData?.full_name}</h6>
            <span>{userData?.designation_title}</span>
          </div>
        </div>
        <div className="navbar-nav w-100">
          {menuItems.map((item) => (
            <div key={item.label} className="nav-item dropdown">
              {item.path ? (
                <Link
                  to={item.path}
                  className={`nav-link ${
                    isActive(item.path, item.exact) ? "active" : ""
                  }`}
                >
                  <i className={`fa ${item.icon} me-2`}></i>
                  {item.label}
                </Link>
              ) : (
                <>
                  <Link
                    className={`nav-link dropdown-toggle ${
                      section === item.section ? "active" : ""
                    }`}
                    data-bs-toggle="dropdown"
                  >
                    <i className={`fa ${item.icon} me-2`}></i>
                    {item.label}
                  </Link>
                  <div className="dropdown-menu bg-transparent border-0">
                    {item.dropdown.map((subItem) => (
                      <Link
                        key={subItem.label}
                        to={subItem.path[0]}
                        className={`dropdown-item ${
                          subItem.path.some((path) =>
                            isActive(path, subItem.exact)
                          )
                            ? "active"
                            : ""
                        }`}
                      >
                        <i className="fas fa-chevron-right me-1"></i>
                        {subItem.label}
                      </Link>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </nav>
    </div>
  );
}
